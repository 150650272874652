import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeArtworkTemplateItemSkeleton } from "./TypeArtworkTemplateItem";
import type { TypeCardRowComponentSkeleton } from "./TypeCardRowComponent";
import type { TypeCategoryPageSkeleton } from "./TypeCategoryPage";
import type { TypeFaqItemSkeleton } from "./TypeFaqItem";
import type { TypeImageWithAltTextSkeleton } from "./TypeImageWithAltText";
import type { TypeProductAttributeInfoSkeleton } from "./TypeProductAttributeInfo";
import type { TypeProductCardsComponentSkeleton } from "./TypeProductCardsComponent";
import type { TypeProductModalComponentSkeleton } from "./TypeProductModalComponent";
import type { TypeSeoSkeleton } from "./TypeSeo";

/**
 * Fields type definition for content type 'TypeProductPage'
 * @name TypeProductPageFields
 * @type {TypeProductPageFields}
 * @memberof TypeProductPage
 */
export interface TypeProductPageFields {
    /**
     * Field type definition for field 'title' (Title)
     * @name Title
     * @localized false
     * @summary This is the header text that will appear at the top of the Product Page
     */
    title: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'menuTitle' (Menu Title)
     * @name Menu Title
     * @localized false
     */
    menuTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'slug' (Slug)
     * @name Slug
     * @localized false
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'highlights' (Highlights)
     * @name Highlights
     * @localized false
     */
    highlights?: EntryFieldTypes.RichText;
    /**
     * Field type definition for field 'seoMetadata' (SEO metadata)
     * @name SEO metadata
     * @localized false
     */
    seoMetadata?: EntryFieldTypes.EntryLink<TypeSeoSkeleton>;
    /**
     * Field type definition for field 'masterCategory' (Master Category)
     * @name Master Category
     * @localized false
     * @summary This is the category used in the breadcrumbs component
     */
    masterCategory?: EntryFieldTypes.EntryLink<TypeCategoryPageSkeleton>;
    /**
     * Field type definition for field 'heroImage' (Hero Image)
     * @name Hero Image
     * @localized false
     */
    heroImage?: EntryFieldTypes.AssetLink;
    /**
     * Field type definition for field 'gallery' (Image Gallery)
     * @name Image Gallery
     * @localized false
     */
    gallery?: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
    /**
     * Field type definition for field 'imageGrid' (Image Grid)
     * @name Image Grid
     * @localized false
     * @summary Select 1 image for grid
     */
    imageGrid?: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
    /**
     * Field type definition for field 'shortDescription' (Short Description)
     * @name Short Description
     * @localized true
     * @summary Used when products are referenced in other pages (such as Card Row Component)
     */
    shortDescription: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'sku' (Product SKU)
     * @name Product SKU
     * @localized false
     */
    sku?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'discountPercentOff' (Discount Percent Off)
     * @name Discount Percent Off
     * @localized false
     */
    discountPercentOff?: EntryFieldTypes.Integer;
    /**
     * Field type definition for field 'badges' (Badges)
     * @name Badges
     * @localized false
     */
    badges?: EntryFieldTypes.Array<EntryFieldTypes.Symbol<"X% off" | "featured" | "new" | "sale">>;
    /**
     * Field type definition for field 'artworkTemplates' (Artwork Templates)
     * @name Artwork Templates
     * @localized false
     * @summary Select artwork templates here
     */
    artworkTemplates?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeArtworkTemplateItemSkeleton>>;
    /**
     * Field type definition for field 'artworkGuides' (Artwork Guides)
     * @name Artwork Guides
     * @localized false
     */
    artworkGuides?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeArtworkTemplateItemSkeleton>>;
    /**
     * Field type definition for field 'keywords' (Keywords)
     * @name Keywords
     * @localized false
     */
    keywords?: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
    /**
     * Field type definition for field 'addToBasketModal' (Add to Basket Modal)
     * @name Add to Basket Modal
     * @localized false
     */
    addToBasketModal?: EntryFieldTypes.EntryLink<TypeProductModalComponentSkeleton>;
    /**
     * Field type definition for field 'accordionItems' (Accordion Items)
     * @name Accordion Items
     * @localized false
     */
    accordionItems?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeFaqItemSkeleton>>;
    /**
     * Field type definition for field 'bottomSection' (Bottom Section)
     * @name Bottom Section
     * @localized false
     */
    bottomSection?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeCardRowComponentSkeleton | TypeProductCardsComponentSkeleton>>;
    /**
     * Field type definition for field 'attributesInfo' (Attributes Info)
     * @name Attributes Info
     * @localized false
     * @summary Custom popup messages for configurator
     */
    attributesInfo?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeProductAttributeInfoSkeleton>>;
    /**
     * Field type definition for field 'heroImage_imageWithAltText' (Hero Image using imageWithAltText)
     * @name Hero Image using imageWithAltText
     * @localized false
     */
    heroImage_imageWithAltText?: EntryFieldTypes.EntryLink<TypeImageWithAltTextSkeleton>;
    /**
     * Field type definition for field 'gallery_imageWithAltText' (Image Gallery using imageWithAltText)
     * @name Image Gallery using imageWithAltText
     * @localized false
     */
    gallery_imageWithAltText?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeImageWithAltTextSkeleton>>;
    /**
     * Field type definition for field 'imageGrid_imageWithAltText' (Image Grid using imageWithAltText)
     * @name Image Grid using imageWithAltText
     * @localized false
     */
    imageGrid_imageWithAltText?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeImageWithAltTextSkeleton>>;
    /**
     * Field type definition for field 'isProductTurnedOff' (Turn off product to show unavailable form)
     * @name Turn off product to show unavailable form
     * @localized false
     */
    isProductTurnedOff?: EntryFieldTypes.Boolean;
    /**
     * Field type definition for field 'productUnavailableFormKey' (Product Unavailable Form Key)
     * @name Product Unavailable Form Key
     * @localized false
     * @summary formcrafts form unique key
     */
    productUnavailableFormKey?: EntryFieldTypes.Symbol;
}

/**
 * Entry skeleton type definition for content type 'productPage' (📄 Product Page)
 * @name TypeProductPageSkeleton
 * @type {TypeProductPageSkeleton}
 * @author 4DB2lvMevuhSxpDRuXX3Hr
 * @since 2023-09-14T10:54:30.162Z
 * @version 273
 */
export type TypeProductPageSkeleton = EntrySkeletonType<TypeProductPageFields, "productPage">;
/**
 * Entry type definition for content type 'productPage' (📄 Product Page)
 * @name TypeProductPage
 * @type {TypeProductPage}
 * @author 4DB2lvMevuhSxpDRuXX3Hr
 * @since 2023-09-14T10:54:30.162Z
 * @version 273
 */
export type TypeProductPage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeProductPageSkeleton, Modifiers, Locales>;

export function isTypeProductPage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeProductPage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'productPage'
}
